<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color rounded-lg overflow-hidden">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Giriş!</h4>
                  <p>Hoş geldiňiz, ulgama girmek üçin.</p>
                </div>

                <!-- <vs-tabs> -->
                  <!-- <vs-tab label="Admin"> -->
                    <div>
                      <vs-input
                          v-validate="'required|min:3'"
                          data-vv-validate-on="blur"
                          name="username"
                          icon-no-border
                          icon="icon icon-user"
                          icon-pack="feather"
                          label-placeholder="Username"
                          v-model="username"
                          class="w-full"/>
                      <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                      <vs-input
                          data-vv-validate-on="blur"
                          v-validate="'required|min:5|max:20'"
                          type="password"
                          name="password"
                          icon-no-border
                          icon="icon icon-lock"
                          icon-pack="feather"
                          label-placeholder="Password"
                          v-model="password"
                          class="w-full mt-6" />
                      <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                      <div class="flex flex-wrap justify-between mt-5 mb-1">
                        <!-- <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox> -->
                        <!-- <router-link to="">Forgot Password?</router-link> -->
                        <vs-button class="ml-auto float-right" :disabled="!validateForm" @click="submitLogin">Login</vs-button>
                      </div>
                      <!-- <vs-button  type="border">Register</vs-button> -->
                    </div>
                  <!-- </vs-tab> -->

                  <!-- <vs-tab label="Supplier">
                    <div>
                      <vs-input
                          v-validate="'required|min:3'"
                          data-vv-validate-on="blur"
                          name="username"
                          icon-no-border
                          icon="icon icon-user"
                          icon-pack="feather"
                          label-placeholder="Username"
                          v-model="username"
                          class="w-full"/>
                      <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                      <vs-input
                          data-vv-validate-on="blur"
                          v-validate="'required|min:5|max:20'"
                          type="password"
                          name="password"
                          icon-no-border
                          icon="icon icon-lock"
                          icon-pack="feather"
                          label-placeholder="Password"
                          v-model="password"
                          class="w-full mt-6" />
                      <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                      <div class="flex flex-wrap justify-between mt-5 mb-1">
                          <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox>
                      <vs-button class="float-right" :disabled="!validateForm" @click="submitSupplierLogin">Login</vs-button>
                      </div>
                    </div>
                  </vs-tab> -->

                <!-- </vs-tabs> -->

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default{
  //ToDo: Uncomment on production

  // inject: ['messaging'],
  data () {
    return {
      username: '',
      password: '',
      checkbox_remember_me: false
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.username !== '' && this.password !== ''
    }
  },
  methods: {
    checkLogin () {
      return this.$store.getters['auth/isUserLoggedIn']
    },
    async submitLogin () {
      if (this.checkLogin()) return
      this.$vs.loading()
      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          username: this.username,
          password: this.password
        }
      }
      await this.$store.dispatch('auth/loginJWT', payload)
        .then(() => {
          this.$acl.change(this.$store.state.auth.userData.role)
          // this.setDeviceToken()
          this.$router.push(this.$router.currentRoute.query.to || '/')
          this.$vs.loading.close()
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })      
    },
    // async submitSupplierLogin () {
    //   if (this.checkLogin()) return
    //   this.$vs.loading()
    //   const payload = {
    //     username: this.username,
    //     password: this.password
    //   }
    //   await this.$store.dispatch('auth/loginSupplierJWT', payload)
    //     .then((res) => {
    //       console.log(res)
    //       this.$acl.change('supplier')
    //       // this.setDeviceToken()
    //       this.$router.push('/supplier-dashboard')
    //       this.$vs.loading.close()
    //     })
    //     .catch(error => {
    //       this.$vs.loading.close()
    //       console.log('error is: ')
    //       console.log(JSON.stringify(error))
    //       this.$vs.notify({
    //         title: 'Error',
    //         text: error.message,
    //         iconPack: 'feather',
    //         icon: 'icon-alert-circle',
    //         color: 'danger'
    //       })
    //     })      
    // },
    async setDeviceToken () {
      //ToDo: Uncomment on pruduction

      // this.messaging.getToken({ vapidKey: 'BDAeTr7hzzb4RFsKq5-oyX-kc-dcJ2oEy3S9ddGv3NfD0eB2wT5R4u1fkN_TnTmsTxvea_maSIUl3o6zD3jnQpo' })
      //   .then((currentToken) => {
      //     if (currentToken) {
      //       console.log('client token', currentToken)
      //       this.$http.post('update-my-device-token', { device_token: currentToken }).then(() => {
      //         console.log('Token updated')
      //       }).catch(err => { console.log(err) })
      //     } else {
      //       console.log('No registration token available. Request permission to generate one.')
      //     }
      //   }).catch((err) => {
      //     console.log('An error occurred while retrieving token. ', err)
      //   })
    }
  }
}
</script>

<style lang="scss">
.login-tabs-container {
  // min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
